import React, { Fragment } from 'react'

import { vwpx } from '@/utils'

const Teaser = ({ category, headline, text, link }) => (
  <Fragment>
    {category &&
      React.cloneElement(category, { style: { marginBottom: vwpx(5) } })}
    {headline &&
      React.cloneElement(headline, { style: { marginBottom: vwpx(25) } })}
    {text && React.cloneElement(text, { style: { marginBottom: vwpx(15) } })}
    {link}
  </Fragment>
)

export default Teaser

import React, { Fragment, useRef, useState } from 'react'
import ReactPlayer from 'react-player/file'
import { graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import Layout, { Composition, Box, useMediaQuery } from 'atomic-layout'
import useClickOutside from 'use-click-outside'
import Marquee from 'react-fast-marquee'
import screenfull from 'screenfull'

import logo from '@/images/logo.svg'
import { vwpx, fluidFontSize, randomID, handleExternalLink } from '@/utils'
import { desktopTemplateCols } from '@/theme'
import PageEmployeeListingBlock from '@/components/PageEmployeeListingBlock'
import AccentColorAwareRatioImage from '@/components/AccentColorAwareRatioImage'
import Teaser from '@/components/Teaser'
import Text from '@/components/Text'
import Footer from '@/components/Footer'

Layout.configure({
  defaultBreakpointName: 'desktop',
  defaultBehavior: 'down',
  breakpoints: {
    mobile: {
      maxWidth: 500,
    },
    desktop: {
      minWidth: 500,
    },
  },
})

export const TeaserLink = ({ href, label, ...props }) => (
  <Text
    type="teaserLink"
    as="a"
    rel="noopener noreferrer"
    href={href}
    {...props}
    css={{
      position: 'relative',
      display: 'inline-block',
      padding: '0.7em 1.6em 0.6em',
      lineHeight: 1,
      border: '2px solid black',
      alignSelf: 'flex-start',
      '&:hover': {
        border: '2px solid var(--primary)',
      },
    }}
  >
    {label}
  </Text>
)

const Section = ({
  title,
  fullscreen: isFullscreen,
  accentColor,
  anchorId,
  children,
  onVisibilityChange,
}) => {
  const isInitial = React.useRef(true)
  const isMobile = useMediaQuery({ maxWidth: '500px' })
  const threshold = isMobile ? 0.2 : 0.05
  const [inViewRef, inView] = useInView({
    threshold,
  })

  React.useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false
    } else {
      if (inView) {
        onVisibilityChange(anchorId)
      }
    }
  }, [inView, anchorId, onVisibilityChange])

  let newGap
  if (anchorId === 'jobs') {
    newGap = vwpx(75)
  } else if (anchorId === 'brands') {
    newGap = 0
  } else {
    newGap = vwpx(150)
  }

  let newPaddingTop
  if (!isFullscreen && anchorId !== 'intro') {
    newPaddingTop = vwpx(150)
  } else if (anchorId === 'intro') {
    newPaddingTop = vwpx(100, 500)
  }

  return (
    <Composition
      ref={inViewRef}
      id={anchorId}
      css={{
        minHeight: '100vh',
        '--primary': accentColor.primary,
        '--secondary': accentColor.secondary,
        '--tertiary': accentColor.tertiary,
        backgroundColor: accentColor.secondary,
        paddingTop: newPaddingTop,
        paddingBottom:
          anchorId === 'nachhaltigkeit' || anchorId === 'insights'
            ? vwpx(200)
            : 0,
        '@media(max-width: 500px)': {
          paddingTop: anchorId !== 'home' && anchorId !== 'intro' && vwpx(350),
        },
      }}
      templateCols={desktopTemplateCols}
      gapRow={newGap}
    >
      {anchorId !== 'intro' && anchorId !== 'insights' && !isFullscreen && (
        <InnerContent
          css={{
            borderTop: '2px solid #000',
            paddingTop: vwpx(10),
            '@media(max-width: 500px)': {
              paddingTop: vwpx(40),
            },
          }}
        >
          <Text
            className="separator"
            type="categoryRegular"
            css={{
              fontSize: fluidFontSize(12),
              fontWeight: 'normal',
              fontFamily: 'Circular',
              '@media(max-width: 500px)': {
                fontSize: vwpx(40),
                paddingTop: vwpx(35),
                letterSpacing: '0.1em',
              },
            }}
          >
            FOND OF{' '}
            <Text
              as="span"
              type="categoryBold"
              css={{
                fontSize: fluidFontSize(12),
                '@media(max-width: 500px)': {
                  fontSize: vwpx(40),
                  letterSpacing: '0.1em',
                },
              }}
            >
              {title}
            </Text>
          </Text>
        </InnerContent>
      )}
      {children}
    </Composition>
  )
}

const FullWidth = props => (
  <Box colStart="full-start" colEnd="full-end" {...props} />
)

const InnerContent = props => (
  <Box colStart="content-start" colEnd="content-end" {...props} />
)

const InsetContent = props => (
  <Box colStart="inset-start" colEnd="inset-end" {...props} />
)

const PageVideoBlockSource = ({ source: { url, contentType } }) => (
  <source src={url} type={contentType} />
)

const PageHeroVideoBlock = ({
  media: { desktopPosterImage, mobilePosterImage, desktop, mobile },
}) => {
  const isMobile = useMediaQuery({ maxWidth: '500px' })

  return (
    <Box colStart="full-start" colEnd="full-end" css={{ position: 'relative' }}>
      <div
        className="introVideo"
        css={{
          position: 'absolute',
          bottom: '90px',
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: fluidFontSize(14),
          fontFamily: 'Circular',
          fontWeight: 'normal',
          color: 'rgb(255,255,255)',
          textAlign: 'center',
        }}
      >
        scrollen
      </div>
      <video
        css={{
          height: 'calc(100vh - 40px)',
          display: 'block',
          width: '100%',
          backgroundColor: '#000',
          objectFit: 'cover',
        }}
        poster={
          !isMobile ? desktopPosterImage.fixed.src : mobilePosterImage.fixed.src
        }
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline={true}
      >
        <PageVideoBlockSource
          source={isMobile ? { ...mobile.file } : { ...desktop.file }}
        />
      </video>

      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: 'calc(100vh - 40px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'Circular, Arial',
          fontWeight: 900,
          fontSize: '20vw',
          color: 'transparent',
          pointerEvents: 'none',
          WebkitTextStroke: '0.02em white',
          '@media(max-width: 500px)': {
            fontSize: '70vw',
            WebkitTextStroke: '0.01em white',
          },
        }}
      >
        <Marquee
          style={{
            background: 'transparent',
            overflowY: 'hidden',
          }}
          speed={isMobile ? '40' : '60'}
          direction="left"
          delay="0.05"
          gradient="false"
          gradientWidth="0"
        >
          <div
            css={{
              marginRight: vwpx(100),
              '@media(max-width: 500px)': {
                marginRight: vwpx(300),
              },
            }}
          >
            FOND OF
          </div>
        </Marquee>
      </div>
    </Box>
  )
}

const PageVideoBlock = ({ _media: { desktop } }) => {
  // desktopPosterImage
  // const [inViewRef, inView] = useInView({ threshold: 0.5 })
  const containerRef = React.useRef(null)
  const playerRef = React.useRef(null)

  const [playerState, setPlayerState] = useState({
    isReady: false,
    isPlaying: false,
    isBuffering: false,
    isFullscreen: false,
    showSubtitles: false,
    duration: 0,
    playedFraction: 0,
    loadedFraction: 0,
    playedSeconds: 0,
    loadedSeconds: 0,
    isMuted: false,
  })

  const playerConfig = {
    file: {
      attributes: {
        crossOrigin: 'true',
      },
    },
  }
  const playerInstance = playerRef.current

  React.useEffect(() => {
    if (screenfull.isEnabled) {
      const fn = () => {
        setPlayerState(state => ({
          ...state,
          isFullscreen: screenfull.isFullscreen,
        }))
      }
      // eslint-disable-next-line no-unused-expressions
      screenfull?.on('change', fn)
      return () => screenfull.off('change', fn)
    }
  }, [])

  return (
    <Box
      // ref={inViewRef}
      colStart="content-start"
      colEnd="content-end"
      css={{
        width: vwpx(1100),
        height: `calc(${vwpx(1100)} * 9/16)`,
        position: 'relative',
        backgroundColor: 'var(--tertiary)',
        filter: 'saturate(70%)',
        '@media(max-width: 500px)': {
          width: vwpx(1100),
          height: `calc(${vwpx(1800)} * 3/4)`,
        },
      }}
    >
      <div
        className="videoPlayer"
        ref={containerRef}
        css={{
          width: vwpx(1100),
          height: `calc(${vwpx(1100)} * 9/16)`,
          objectFit: 'cover',
          display: 'block',
          mixBlendMode:
            !playerState.isPlaying &&
            playerState.playedSeconds === 0 &&
            'overlay',
          filter:
            !playerState.isPlaying &&
            playerState.playedSeconds === 0 &&
            'grayscale(1) contrast(0.65)',
          '@media(max-width: 500px)': {
            height: `calc(${vwpx(1800)} * 3/4)`,
          },
        }}
      >
        <ReactPlayer
          className="player"
          ref={playerRef}
          config={playerConfig}
          url={desktop.file.url}
          playing={playerState.isPlaying}
          playsinline
          onProgress={({
            played: playedFraction,
            loaded: loadedFraction,
            playedSeconds,
            loadedSeconds,
          }) =>
            setPlayerState(state => ({
              ...state,
              playedFraction,
              loadedFraction,
              playedSeconds,
              loadedSeconds,
            }))
          }
          // prettier-ignore
          onReady={() => setPlayerState(state => ({ ...state, isReady: true }))}
          // prettier-ignore
          onPlay={() => setPlayerState(state => ({ ...state, isPlaying: true }))}
          // prettier-ignore
          muted={playerState.isMuted}
          // prettier-ignore
          onPause={() => setPlayerState(state => ({ ...state, isPlaying: false }))}
          // prettier-ignore
          onDuration={(duration) => setPlayerState(state => ({ ...state, duration }))}
          // prettier-ignore
          onBuffer={() => setPlayerState(state => ({ ...state, isBuffering: true }))}
          // prettier-ignore
          onBufferEnd={() => setPlayerState(state => ({ ...state, isBuffering: false }))}
        />
      </div>

      {/* {!playerState.isPlaying && playerState.playedSeconds === 0 && (
        <Text
          type="videoOverlay"
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            mixBlendMode: 'overlay',
            opacity: 0.6,
            pointerEvents: 'none',
            '@media(max-width: 500px)': {
              fontSize: fluidFontSize(180),
            },
          }}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              zIndex: 3,
            }}
            onClick={() =>
              setPlayerState(state => ({ ...state, isPlaying: true }))
            }
          >
            play
          </div>
        </Text>
      )} */}

      <div
        css={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          maxWidth: '60%',
          width: vwpx(380),
          height: vwpx(85),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          paddingLeft: vwpx(20),
          paddingRight: vwpx(20),
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          '@media(max-width: 500px)': {
            maxWidth: '100%',
            width: vwpx(1100),
            height: `calc(${vwpx(1500)} / 6.5)`,
          },
        }}
      >
        <Text
          type="videoControl"
          onClick={() => {
            if (playerState.isPlaying) {
              setPlayerState(state => ({ ...state, isPlaying: false }))
            } else {
              setPlayerState(state => ({ ...state, isPlaying: true }))
            }
          }}
          css={{
            '@media(max-width: 500px)': { fontSize: vwpx(45) },
          }}
        >
          {playerState.isPlaying ? 'Pause' : 'Play'}
        </Text>

        <Text
          type="videoControl"
          onClick={() =>
            setPlayerState(state => ({
              ...state,
              isMuted: !playerState.isMuted,
            }))
          }
          css={{
            '@media(max-width: 500px)': { fontSize: vwpx(45) },
          }}
        >
          {playerState.isMuted ? 'Ton Ein' : 'Ton Aus'}
        </Text>

        <Text
          type="videoControl"
          onClick={() => {
            if (screenfull.isEnabled) {
              screenfull.request(playerInstance?.getInternalPlayer())
            } else if (!screenfull.isEnabled) {
              // eslint-disable-next-line no-unused-expressions
              playerInstance?.getInternalPlayer().webkitEnterFullscreen()
            }
          }}
          css={{
            '@media(max-width: 500px)': { fontSize: vwpx(45) },
          }}
        >
          vollbild
        </Text>
      </div>
    </Box>
  )
}

const PageHeroTeaserBlock = ({ headline, text, linkLabel, linkHref }) => (
  <Fragment>
    <Box
      colStart="inset-start"
      colEnd="column-center-end"
      colStartMobile="content-start"
      colEndMobile="content-end"
      as={Text}
      type="largeHeadlineSerif"
      css={{
        lineHeight: 1.1,
        '@media(max-width: 500px)': {
          fontSize: fluidFontSize(47.5, 500),
        },
      }}
    >
      <span css={{ fontFamily: 'Circular', fontWeight: 400 }}>FOND OF </span>
      {headline}
    </Box>
    <Box
      colStart="column-right-start"
      colEnd="content-end"
      rowStartMobile={2}
      colStartMobile="content-start"
      colEndMobile="content-end"
      css={{
        '@media(max-width: 500px)': {
          marginTop: '-20px',
          marginBottom: vwpx(120),
        },
      }}
    >
      <Teaser
        text={
          <Text
            type="teaserText"
            css={{
              lineHeight: 1.6,
              '@media(max-width: 500px)': {
                fontSize: fluidFontSize(13),
              },
            }}
          >
            {text}
          </Text>
        }
        link={
          <TeaserLink
            css={{
              '@media(max-width: 500px)': {
                fontSize: fluidFontSize(13),
                marginTop: vwpx(85),
              },
            }}
            href={`#${linkHref}`}
            label={linkLabel}
          />
        }
      />
    </Box>
  </Fragment>
)

const PageLargeTeaserBlock = ({
  category,
  alignment,
  headline,
  text,
  linkLabel,
  linkHref,
  accentColor,
  media: { image, imageDescription },
}) => {
  const isLeftAligned = alignment === 'left'
  const isMobile = useMediaQuery({ maxWidth: 500 })

  return (
    <Composition
      css={{
        '--primary': accentColor.primary,
        '--secondary': accentColor.secondary,
        '--tertiary': accentColor.tertiary,
        lineHeight: 1.6,
        gridGap: vwpx(60),
        '@media(max-width: 945px)': {
          gridGap: vwpx(80),
        },
        '@media(max-width: 680px)': {
          gridGap: vwpx(100),
        },
        '@media(max-width: 500px)': {
          fontSize: vwpx(35),
          lineHeight: 1.6,
        },
      }}
      colStart={
        isLeftAligned ? 'teaser-block-left-start' : 'teaser-block-right-start'
      }
      colEnd={
        isLeftAligned ? 'teaser-block-left-end' : 'teaser-block-right-end'
      }
      templateCols={
        isLeftAligned
          ? `[image-start] ${vwpx(
              485,
              1236
            )} [image-end text-start] 1fr [text-end]`
          : `[text-start] 1fr [text-end image-start] ${vwpx(
              485,
              1236
            )} [image-end]`
      }
      templateColsMobile={
        isLeftAligned
          ? '[image-mobile-start] 10fr [text-mobile-start] 130fr [image-mobile-end] 10fr [ text-mobile-end] 10fr'
          : '10fr [text-mobile-start] 10fr [image-mobile-start] 130fr [text-mobile-end] 10fr [image-mobile-end]'
      }
      alignItems="center"
      colStartMobile="full-start"
      colEndMobile="full-end"
      gapColMobile={vwpx(30)}
      gapRowMobile={vwpx(30)}
    >
      <Box
        className={
          isLeftAligned ? 'largeTeaserBlockLeft' : 'largeTeaserBlockRight'
        }
        rowStart={1}
        colStart="image-start"
        colEnd="image-end"
        as={AccentColorAwareRatioImage}
        image={image}
        description={imageDescription}
        ratio={[22, 25]}
        width={isMobile ? vwpx(420, 500) : vwpx(485, 1236)}
        colStartMobile="image-mobile-start"
        colEndMobile="image-mobile-end"
      />
      <Box
        rowStart={1}
        colStart="text-start"
        colEnd="text-end"
        rowStartMobile={2}
        colStartMobile="text-mobile-start"
        colEndMobile="text-mobile-end"
        css={{
          width: '100%',
        }}
      >
        <Teaser
          category={
            <Text
              type="categoryRegular"
              css={{
                fontWeight: 400,
                '@media(max-width: 500px)': {
                  fontSize: vwpx(40),
                  lineHeight: vwpx(50),
                  marginTop: vwpx(170),
                  letterSpacing: '0.1em',
                },
              }}
            >
              {category}
            </Text>
          }
          headline={
            <Text
              type="largeHeadlineSerif"
              css={{
                lineHeight: 1.1,
                marginBottom: '0.25em',
                '@media(max-width: 500px)': {
                  fontSize: vwpx(120),
                  marginTop: vwpx(25),
                },
              }}
            >
              {headline}
            </Text>
          }
          text={
            <Text
              type="teaserText"
              css={{
                paddingRight: vwpx(205),
                lineHeight: 1.6,
                '@media(max-width: 500px)': {
                  fontSize: fluidFontSize(46),
                  marginTop: vwpx(85),
                  fontWeight: 400,
                  paddingRight: 0,
                },
              }}
            >
              {text}
            </Text>
          }
          link={
            <TeaserLink
              href={linkHref}
              label={linkLabel}
              onClick={event => handleExternalLink(event)}
              css={{
                '@media(max-width: 500px)': {
                  fontSize: vwpx(46),
                  letterSpacing: '0.15em',
                  marginTop: vwpx(85),
                },
              }}
            />
          }
        />
      </Box>
    </Composition>
  )
}

const PageSubHeadingBlock = ({ alignment, headline, text }) => {
  const isMobile = useMediaQuery({ maxWidth: '500px' })
  const desktopAlignment = alignment === 'left' ? 'flex-start' : 'center'
  const mobileAlignment = 'left'

  return (
    <InsetContent
      flex
      flexDirection="column"
      alignItems={isMobile ? mobileAlignment : desktopAlignment}
      css={{
        '@media(max-width: 500px)': {
          marginTop: vwpx(205),
        },
      }}
    >
      <Text
        type="subHeading"
        css={{
          lineHeight: 1.2,
          marginBottom: '0.25em',
          '@media(max-width: 500px)': {
            fontSize: fluidFontSize(160),
          },
        }}
      >
        {headline}
      </Text>
      <Text
        type="subHeadingTextSerif"
        css={{
          lineHeight: 1.2,
          '@media(max-width: 500px)': {
            fontSize: fluidFontSize(100),
            marginBottom: vwpx(40),
          },
        }}
      >
        {text}
      </Text>
    </InsetContent>
  )
}

const PageSmallTeaserItem = ({
  category,
  headline,
  linkLabel,
  linkHref,
  accentColor,
  media: { image, imageDescription },
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 500 })
  return (
    <Box
      {...props}
      css={{
        '--primary': accentColor.primary,
        '--secondary': accentColor.secondary,
        '--tertiary': accentColor.tertiary,
        display: 'grid',
      }}
      templateColsMobile="[text-start] 15fr [image-start] 70fr [text-end] 35fr [image-end] 10fr"
      gapRowMobile={vwpx(45)}
      templateRowsMobile={2}
    >
      <Box
        rowStartMobile={2}
        css={{
          marginBottom: vwpx(44),
          '@media(max-width: 500px)': {
            marginBottom: vwpx(180),
          },
        }}
        colStartMobile="text-start"
        colEndMobile="text-end"
      >
        <Teaser
          category={
            <Text
              type="categoryRegular"
              css={{
                fontSize: fluidFontSize(12),
                lineHeight: 1.6,
                '@media(max-width: 500px)': {
                  fontWeight: 400,
                  marginTop: vwpx(90),
                  fontSize: vwpx(40),
                  letterSpacing: '0.1em',
                },
              }}
            >
              {category}
            </Text>
          }
          headline={
            <Text
              type="smallTeaserHeadline"
              css={{
                fontSize: fluidFontSize(38),
                lineHeight: 1.2,
                marginBottom: '0.5em',
                '@media(max-width: 500px)': {
                  fontSize: fluidFontSize(108),
                  lineHeight: 1.2,
                  marginTop: vwpx(40),
                },
              }}
            >
              {headline}
            </Text>
          }
          link={
            <TeaserLink
              label={linkLabel}
              href={linkHref}
              onClick={event => handleExternalLink(event)}
              css={{
                fontSize: fluidFontSize(16),
                '@media(max-width: 500px)': {
                  fontSize: fluidFontSize(46),
                  letterSpacing: '0.15em',
                  marginTop: vwpx(90),
                },
              }}
            />
          }
        />
      </Box>
      <Box
        as={AccentColorAwareRatioImage}
        width={isMobile ? vwpx(330, 500) : vwpx(300)}
        image={image}
        description={imageDescription}
        ratio={isMobile ? [3, 4] : [9, 16]}
        colStartMobile="image-start"
        colEndMobile="image-end"
        rowStartMobile={1}
      ></Box>
    </Box>
  )
}

const PageSmallTeaserListingBlock = ({
  teasers: [firstTeaser, secondTeaser, thirdTeaser],
}) => (
  <Composition
    colStart="content-start"
    colEnd="content-end"
    templateCols={`
      [left-column-start] ${vwpx(300)} [left-column-end] 1fr
      [center-column-start] ${vwpx(300)} [center-column-end] 1fr
      [right-column-start] ${vwpx(300)} [right-column-end]
    `}
    templateColsMobile="[content-start] 1fr [content-end]"
    css={{
      marginBottom: vwpx(50),
    }}
  >
    <PageSmallTeaserItem
      {...firstTeaser}
      colStart="left-column-start"
      colEnd="left-column-end"
      colStartMobile="content-start"
      colEndMobile="content-end"
      css={{
        '@media(max-width: 500px)': {
          marginTop: vwpx(95),
          width: '110%',
          marginLeft: '-10px',
        },
      }}
    />

    <PageSmallTeaserItem
      {...secondTeaser}
      colStart="center-column-start"
      colEnd="center-column-end"
      colStartMobile="content-start"
      colEndMobile="content-end"
      css={{
        marginTop: vwpx(95),
        width: '110%',
        marginLeft: '-10px',
      }}
    />

    <PageSmallTeaserItem
      {...thirdTeaser}
      colStart="right-column-start"
      colEnd="right-column-end"
      colStartMobile="content-start"
      colEndMobile="content-end"
      css={{
        '@media(max-width: 500px)': {
          marginTop: vwpx(95),
          width: '110%',
          marginLeft: '-10px',
        },
      }}
    />
  </Composition>
)

const PageJobListingBlock = ({ category, jobsInCategory }) => (
  <InnerContent>
    <div
      css={{
        fontSize: fluidFontSize(12),
        '@media(max-width: 500px)': {
          fontSize: fluidFontSize(45),
        },
      }}
    >
      {category}
    </div>

    {jobsInCategory.map(job => (
      <Box
        key={job.linkHref}
        as="a"
        href={job.linkHref}
        target="_blank"
        css={{
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <Text
          type="jobListing"
          key={job.id}
          css={{
            marginBottom: '0.5em',
            letterSpacing: '0.02em',
            '@media(max-width: 500px)': {
              fontSize: fluidFontSize(100),
              lineHeight: 1.2,
              marginTop: vwpx(20),
              marginBottom: '1em',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'flex-end',
            },
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: job.title }}></span>
          <Text
            as="span"
            type="jobListingGenderTag"
            css={{
              lineHeight: 1.6,
              '@media(max-width: 500px)': {
                fontSize: fluidFontSize(40),
                marginLeft: vwpx(20),
              },
            }}
          >
            (M/W/D)
          </Text>
        </Text>
      </Box>
    ))}
  </InnerContent>
)

const PageJobListingFooterBlock = ({
  jobPortalLinkExplainer,
  jobPortalLinkLabel,
  jobPortalLinkHref,
}) => (
  <FullWidth flex justifyContent="center">
    <Box
      css={{
        maxWidth: vwpx(300),
        marginBottom: vwpx(150),
        '@media(max-width: 500px)': { maxWidth: vwpx(420, 500) },
      }}
      flex
      flexDirection="column"
      justifyContent="center"
    >
      <div
        css={{
          width: vwpx(38),
          height: vwpx(3),
          marginBottom: vwpx(30),
          backgroundColor: '#000',
          alignSelf: 'center',
          '@media(max-width: 500px)': {
            width: vwpx(148),
            height: vwpx(6),
          },
        }}
      />
      <Teaser
        text={
          <Text
            type="teaserText"
            css={{
              lineHeight: 1.6,
              fontWeight: 700,
              '@media(max-width: 500px)': {
                fontSize: fluidFontSize(44),
                fontFamily: 'Circular',
                margin: '0 35px',
              },
            }}
          >
            {jobPortalLinkExplainer}
          </Text>
        }
        link={
          <TeaserLink
            href={jobPortalLinkHref}
            label={jobPortalLinkLabel}
            onClick={event => handleExternalLink(event)}
            css={{
              '@media(max-width: 500px)': {
                fontSize: fluidFontSize(13, 500),
                margin: `${vwpx(30)} 35px 0 35px`,
              },
            }}
          />
        }
      />
    </Box>
  </FullWidth>
)

const AccentColorAwareRatioVideo = ({
  video: { desktopPosterImage, desktop },
  width,
  ratio: [ratioWidth, ratioHeight],
  colorOnlyOnHover,
  mobileWidth,
  mobileRatio: [mobileRatioWidth, mobileRatioHeight],
}) => {
  const ratio = ratioHeight / ratioWidth
  const mobileRatio = mobileRatioHeight / mobileRatioWidth
  return (
    <div
      css={{
        position: 'relative',
        transition: 'background-color 200ms',
        backgroundColor: colorOnlyOnHover ? '#E0D5C9' : 'var(--tertiary)',
        '&:hover': {
          backgroundColor: 'var(--tertiary)',
        },
        maxWidth: '100%',
        maxHeight: '100%',
        height: `calc(${width} * ${ratio})`,
        '@media(max-width: 500px)': {
          width: mobileWidth,
          height: `calc(${mobileWidth} * ${mobileRatio})`,
          maxHeight: '100%',
          maxWidth: '100%',
        },
      }}
    >
      <video
        poster={desktopPosterImage.fixed.src}
        autoPlay
        muted
        loop
        css={{
          display: 'block',
          maxWidth: '100%',
          width,
          height: `calc(${width} * ${ratio})`,
          filter: 'grayscale(1) contrast(0.8)',
          objectFit: 'cover',
          objectPosition: 'center',
          mixBlendMode: 'overlay',
          '@media(max-width: 500px)': {
            width: mobileWidth,
            height: `calc(${mobileWidth} * ${mobileRatio})`,
            maxHeight: '100%',
            maxWidth: '100%',
          },

          '&:hover': colorOnlyOnHover && {
            filter: 'grayscale(1) contrast(0.65)',
          },
        }}
      >
        <source src={desktop.file.url} type={desktop.file.contentType} />
      </video>
    </div>
  )
}

const PageBrandListingBlock = ({ brands }) => {
  const isMobile = useMediaQuery({ maxWidth: '500px' })
  return (
    <Box
      colStart="full-start"
      colEnd="full-end"
      css={{
        position: 'relative',
        paddingTop: vwpx(100),
        paddingBottom: vwpx(100),
        '&::after': {
          position: 'absolute',
          content: '""',
          top: 0,
          left: '50%',
          height: '100%',
          width: 2,
          backgroundColor: 'var(--tertiary)',
          pointerEvents: 'none',
          transform: 'translateX(-50%)',
          zIndex: 1,
        },
      }}
    >
      {brands.map(
        (
          {
            id,
            text: { text },
            name,
            websiteLinkLabel,
            websiteLinkHref,
            media,
          },
          index
        ) => {
          const isEven = index % 2 === 0

          return (
            <Composition
              key={id}
              templateCols={`
              ${vwpx(133)} [left-start]
              1fr [left-end]
              ${vwpx(80)} [right-start]
              1fr [right-end]
              ${vwpx(133)}
            `}
              alignItems="center"
              gapRow={vwpx(20)}
              css={{
                paddingTop: vwpx(30),
                paddingBottom: vwpx(30),
                '& > div': {
                  transitionTimingFunction: 'ease',
                  transitionProperty: 'opacity, transform',
                  transitionDuration: '500ms',
                },
                '&:hover > div': {
                  transform: 'none',
                  opacity: 1,
                },
                '@media(max-width: 500px)': {
                  transform: 'none',
                  opacity: '1',
                  marginTop: vwpx(200),
                },
              }}
              templateColsMobile={
                isEven
                  ? '[content-start] 10fr [image-left-start] 10fr [text-right-start] 205fr [image-left-end] 5fr [text-right-end] 10fr [content-end]'
                  : '[content-start] 10fr [text-left-start] 25fr [image-right-start] 50fr [text-left-end] 15fr [image-right-end] 10fr [content-end]'
              }
              templateRowsMobile={3}
            >
              <Box
                as="a"
                rowStart={1}
                rowStartMobile={1}
                colStart={isEven ? 'left-start' : 'right-start'}
                colEnd={isEven ? 'left-end' : 'right-end'}
                align="flex-start"
                href={websiteLinkHref}
                target="_blank"
                css={{
                  direction: isEven ? 'rtl' : 'ltr',
                  textAlign: 'center',
                  textDecoration: 'none',
                  color: 'inherit',
                  zIndex: 3,
                }}
                colStartMobile={
                  isEven ? 'image-left-start' : 'image-right-start'
                }
                colEndMobile={isEven ? 'image-left-end' : 'image-right-end'}
              >
                <Text
                  type="brandName"
                  css={{
                    '@media(max-width: 500px)': {
                      fontSize: fluidFontSize(100),
                    },
                  }}
                >
                  {name}
                </Text>
              </Box>
              <Box
                as="a"
                href={websiteLinkHref}
                target="_blank"
                rowStart={2}
                rowStartMobile={2}
                colStart={isEven ? 'left-start' : 'right-start'}
                colEnd={isEven ? 'left-end' : 'right-end'}
                justify={isEven ? 'flex-end' : 'flex-start'}
                css={{
                  position: 'relative',
                  zIndex: 2,
                  transform: isEven
                    ? `translateX(${vwpx(80 / 2 + 40)})`
                    : `translateX(${vwpx(-(80 / 2) - 100)})`,
                  width: isEven ? vwpx(496) : vwpx(350),
                  '@media(max-width: 500px)': {
                    width: isEven ? vwpx(335, 500) : vwpx(340, 500),
                    marginTop: vwpx(50),
                  },
                }}
                colStartMobile={
                  isEven ? 'image-left-start' : 'image-right-start'
                }
                colEndMobile={isEven ? 'image-left-end' : 'image-right-end'}
              >
                <AccentColorAwareRatioVideo
                  video={media}
                  ratio={isEven ? [4, 5] : [9, 16]}
                  mobileRatio={isEven ? [3, 4] : [9, 16]}
                  width={isEven ? vwpx(496) : vwpx(350)}
                  mobileWidth={isEven ? vwpx(335, 500) : vwpx(305, 500)}
                />
              </Box>

              {!isMobile && (
                <Box
                  rowStart={2}
                  rowStartMobile={3}
                  colStart={isEven ? 'right-start' : 'left-start'}
                  colEnd={isEven ? 'right-end' : 'left-end'}
                  justify={isEven ? 'flex-start' : 'flex-end'}
                  css={{
                    zIndex: 1,
                    opacity: 0,
                    transform: isEven
                      ? `scale(0.95) translateX(${vwpx(-(80 / 2) - 100)})`
                      : `scale(0.95) translateX(${vwpx(80 / 2 + 40)})`,
                  }}
                  colStartMobile={
                    isEven ? 'text-right-start' : 'text-left-start'
                  }
                  colEndMobile={isEven ? 'text-right-end' : 'text-left-end'}
                >
                  <Teaser
                    headline={
                      <Text
                        type="subHeadingTextSerif"
                        css={{
                          margin: isEven ? '0 0 0 45px' : '0 95px 0 0',
                          quotes: '"»" "«"',
                          '&::before': {
                            content: 'open-quote',
                          },
                          '&:after': {
                            content: 'close-quote',
                          },
                          fontSize: fluidFontSize(40),
                          lineHeight: 1.3,
                        }}
                      >
                        {text}
                      </Text>
                    }
                    link={
                      <TeaserLink
                        css={{ margin: isEven ? '0 0 0 45px' : '0 95px 0 0' }}
                        href={websiteLinkHref}
                        label={websiteLinkLabel}
                        target="_blank"
                      />
                    }
                  />
                </Box>
              )}
            </Composition>
          )
        }
      )}
    </Box>
  )
}

const componentMap = {
  PageHeroVideoBlock,
  PageHeroTeaserBlock,
  PageVideoBlock,
  PageLargeTeaserBlock,
  PageSubHeadingBlock,
  PageSmallTeaserListingBlock,
  PageEmployeeListingBlock,
  PageJobListingBlock,
  PageBrandListingBlock,
  PageJobListingFooterBlock,
}

const componentRenderer = ({ __typename, id, ...props }) => {
  if (!__typename || !id) {
    return null
  }

  const type = __typename.replace('Contentful', '')

  if (!type) {
    console.warn('missing typename for', { id, ...props })
    return null
  }

  if (!componentMap[type]) {
    // console.warn('missing component for', type)
    return <InnerContent key={id}>{type}</InnerContent>
  }

  const Component = componentMap[type]
  return <Component key={id} {...props} type={type} />
}

const Navigation = ({ visibleSection, sections }) => {
  const [hoverId, setHoverId] = React.useState(null)

  return (
    <nav
      css={{
        display: 'flex',
        width: '100%',
        position: 'fixed',
        alignItems: 'center',
        backgroundColor: 'white',
        zIndex: '3',
        opacity: '0.8',
      }}
    >
      <div
        css={{
          width: vwpx(85),
          maxWidth: '120px',
          margin: '0 2.5em 0 0.5em',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: vwpx(30),
            maxWidth: '120px',
            margin: '20px 0',
          }}
        >
          <img
            src={logo}
            alt="Fondof logo"
            css={{
              width: vwpx(45),
              height: vwpx(30),
              display: 'block',
            }}
          />
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          width: '100%',
          maxWidth: '75%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '85%',
          }}
        >
          {sections.map(({ id, anchorId }) => {
            const isActive = hoverId === anchorId || visibleSection === anchorId

            return (
              <div
                key={id}
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '0 0.5em',
                }}
              >
                <a
                  key={id}
                  href={`#${anchorId}`}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textDecoration: 'none',
                  }}
                  onMouseOver={() => setHoverId(anchorId)}
                  onMouseLeave={() => setHoverId(null)}
                >
                  <div
                    css={{
                      textTransform: 'uppercase',
                      fontSize: fluidFontSize(11),
                      letterSpacing: '0.15em',
                      color: 'black',
                    }}
                  >
                    {anchorId}
                  </div>
                </a>
                <div
                  css={{
                    width: '100%',
                    height: '1px',
                    border: isActive && '1px inset black',
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

const MobileNav = ({ sections }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const ref = useRef()
  useClickOutside(ref, () => setIsOpen(false))

  return (
    <div ref={ref}>
      <div
        css={{
          position: 'fixed',
          zIndex: 5,
          right: '0px',
          bottom: '0px',
          width: vwpx(325, 500),
          maxWidth: '220px',
          height: '100%',
          minHeight: '570px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          transition: 'all 0.3s ease-in-out 0s',
          transform: isOpen ? 'translateX(0%)' : 'translateX(100%)',
          display: 'flex',
          '@media(max-width: 769px)': {
            height: '50%',
            minHeight: '550px',
          },
          '@media(max-width: 500px)': {
            height: '100%',
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            padding: '20px 30px 100px',
            width: '100%',
            height: '100%',
          }}
        >
          {sections.map(({ id, anchorId }) => (
            <a
              key={id}
              href={`#${anchorId}`}
              css={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '13px',
                letterSpacing: '0.1em',
                lineHeight: '1',
                textDecoration: 'none',
                textTransform: 'uppercase',
                color: 'rgb(0,0,0)',
                flex: '0 1 42px',
              }}
            >
              {anchorId}
            </a>
          ))}
        </div>
      </div>

      <div
        css={{
          position: 'fixed',
          right: '0px',
          bottom: '0px',
          width: '55px',
          backgroundColor: isOpen ? 'transparent' : 'rgb(255, 255, 255, 0.8)',
          transition: 'all 0.3s ease-in-out 0s',
          zIndex: 5,
          '@media(max-width: 769px)': {
            width: '70px',
          },
          '@media(max-width: 500px)': {
            width: '55px',
          },
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            fontSize: vwpx(11, 500),
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            letterSpacing: '0.1rem',
            padding: '12px 8px 40px',
            textTransform: 'uppercase',
            '@media(max-width: 800px)': {
              fontSize: '13px',
            },
          }}
        >
          <img
            src={logo}
            alt="Fondof logo"
            css={{
              width: '100%',
              height: 'auto',
              display: 'block',
              margin: '0 0 5px',
            }}
          />
          {isOpen ? 'Close' : 'Menu'}
        </div>
      </div>
    </div>
  )
}

const MobileCurrentSection = ({ visibleSection }) => (
  <div
    style={{
      position: 'fixed',
      zIndex: 3,
      left: 0,
      bottom: 0,
      height: '100%',
      width: vwpx(85),
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <div
      css={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          transform: 'rotate(-90deg)',
          fontFamily: 'Circular',
          fontWeight: 400,
          fontSize: fluidFontSize(50),
          letterSpacing: '0.15em',
          textTransform: 'uppercase',
          color: visibleSection === 'kontakt' ? '#fff' : 'black',
          '@media(max-width: 800px)': {
            fontSize: '13px',
          },
        }}
      >
        {visibleSection}
      </div>
    </div>
  </div>
)

const IndexPage = ({ sections }) => {
  const [visibleSection, setVisibleSection] = React.useState('intro')
  const isMobile = useMediaQuery({ maxWidth: 769 })

  const newID = randomID()
  const newSections = [
    ...sections,
    {
      anchorId: 'kontakt',
      id: newID,
      title: 'Kontakt',
    },
  ]

  return (
    <Fragment>
      {isMobile ? (
        <Fragment>
          <MobileCurrentSection visibleSection={visibleSection} />
          <MobileNav sections={newSections} />
        </Fragment>
      ) : (
        <Navigation visibleSection={visibleSection} sections={newSections} />
      )}
      {sections.map(({ id, title, accentColor, anchorId, blocks }, index) => (
        <Section
          key={id}
          accentColor={accentColor}
          title={title}
          anchorId={anchorId}
          fullscreen={index === 0}
          onVisibilityChange={setVisibleSection}
        >
          {blocks.map(componentRenderer)}
        </Section>
      ))}
      <Footer onVisibilityChange={setVisibleSection} />
    </Fragment>
  )
}

export default ({
  data: {
    allContentfulPage: {
      nodes: [{ documentTitle, sections }],
    },
  },
}) => <IndexPage documentTitle={documentTitle} sections={sections} />

export const query = graphql`
  {
    allContentfulPage(filter: { internalTitle: { eq: "Page // Home" } }) {
      nodes {
        documentTitle
        sections {
          __typename
          id
          accentColor {
            ...ContentfulAccentColor
          }
          anchorId
          id
          title
          blocks {
            ... on ContentfulPageVideoBlock {
              __typename
              id
              _media: media {
                ...ContentfulVideoAsset
              }
            }
            ... on ContentfulPageSubHeadingBlock {
              __typename
              id
              alignment
              headline
              text
            }
            ... on ContentfulPageSmallTeaserListingBlock {
              __typename
              id
              teasers {
                category
                accentColor {
                  ...ContentfulAccentColor
                }
                headline
                media {
                  ...ContentfulImageAsset
                }
                linkLabel
                linkHref
              }
            }
            ... on ContentfulPageLargeTeaserBlock {
              __typename
              id
              alignment
              category
              accentColor {
                ...ContentfulAccentColor
              }
              media {
                ...ContentfulImageAsset
              }
              headline
              text
              linkHref
              linkLabel
            }
            ... on ContentfulPageJobListingFooterBlock {
              __typename
              id
              jobPortalLinkExplainer
              jobPortalLinkLabel
              jobPortalLinkHref
            }
            ... on ContentfulPageJobListingBlock {
              __typename
              id
              category
              jobsInCategory {
                id
                linkHref
                title
              }
            }
            ... on ContentfulPageHeroVideoBlock {
              __typename
              id
              media {
                ...ContentfulVideoAsset
              }
            }
            ... on ContentfulPageHeroTeaserBlock {
              __typename
              id
              text
              headline
              linkHref
              linkLabel
            }
            ... on ContentfulPageBrandListingBlock {
              __typename
              id
              brands {
                id
                name
                text {
                  text
                }
                websiteLinkHref
                websiteLinkLabel
                media {
                  ... on ContentfulVideoAsset {
                    description
                    desktopPosterImage {
                      fixed {
                        srcSet
                        src
                      }
                    }
                    mobile {
                      fixed {
                        srcSet
                        src
                      }
                      description
                    }
                    mobilePosterImage {
                      fixed {
                        srcSet
                        src
                      }
                      description
                    }
                    desktop {
                      file {
                        url
                        contentType
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

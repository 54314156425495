import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, useMediaQuery, Composition } from 'atomic-layout'

import { vwpx } from '@/utils'

import { desktopTemplateCols } from '@/theme'
import AccentColorAwareRatioImage from '@/components/AccentColorAwareRatioImage'
import Text from '@/components/Text'
import Teaser from '@/components/Teaser'

const shuffle = array => {
  let counter = array.length
  while (counter > 0) {
    const index = Math.floor(Math.random() * counter)
    counter--
    const temp = array[counter]
    array[counter] = array[index]
    array[index] = temp
  }
  return array
}

const Person = ({ name, department, bio, image: { image }, ...rest }) => {
  const isMobile = useMediaQuery({ maxWidth: '500px' })
  return (
    <div
      css={{
        marginBottom: vwpx(50),
      }}
      {...rest}
    >
      <AccentColorAwareRatioImage
        width={isMobile ? vwpx(192, 'vw', 500) : vwpx(300)}
        description={name}
        image={image}
        ratio={[2, 3]}
        colorOnlyOnHover
        css={{
          '@media(max-width: 500px)': {
            marginBottom: vwpx(8, 'vh', 100),
          },
        }}
      >
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: `${isMobile ? vwpx(192, 'vw', 500) : vwpx(300)}`,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: vwpx(16),
            paddingRight: vwpx(16),
            color: '#fff',
            backgroundColor: 'none',

            '&:hover': {
              justifyContent: 'flex-start',
              '[data-name]': {
                display: 'none',
              },
              '[data-bio]': {
                display: 'block',
              },
            },
          }}
        >
          <Text
            data-name
            type="employeeName"
            css={{
              '@media(max-width: 500px)': {
                fontSize: vwpx(50),
              },
            }}
          >
            {name}
          </Text>

          <div data-bio css={{ display: 'none' }}>
            <Text type="employeeBioSans">FOND OF</Text>
            <Text type="employeeBioSerif">
              {department}
              {bio && `, ${bio}`}
            </Text>
          </div>
        </div>
      </AccentColorAwareRatioImage>
    </div>
  )
}

const PageEmployeeListingBlock = ({ text, refreshEmployeeItemsLabel }) => {
  const {
    allContentfulEmployee: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulEmployee {
        nodes {
          id
          department
          bio
          name
          image {
            ...ContentfulImageAsset
          }
        }
      }
    }
  `)

  const [index, setIndex] = React.useState(0)
  const [hasMounted, setHasMounted] = React.useState(false)
  const [shuffledEmployees, setShuffledEmployees] = React.useState([])
  const isMobile = useMediaQuery({ maxWidth: 500 })

  React.useEffect(() => {
    setHasMounted(true)
    setShuffledEmployees(shuffle(nodes))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!hasMounted) {
    return null
  }

  const [topPerson, bottomPerson, ...people] = shuffledEmployees.slice(
    index * 8,
    (index + 1) * 8
  )

  const firstBatch = people.slice(0, 3)
  const secondBatch = people.slice(3, 6)

  return (
    <Composition
      colStart="full-start"
      colEnd="full-end"
      templateCols={desktopTemplateCols}
      templateColsMobile="[content-start] 10fr [image-left-start] 50fr [image-left-end] 10fr [image-right-start] 50fr [image-right-end] 10fr [content-end]"
      css={{
        '@media(max-width: 500px)': {
          marginTop: vwpx(15, 'vh', 100),
        },
      }}
    >
      <Box
        colStart="column-left-start"
        colEnd="column-left-end"
        colStartMobile="image-left-start"
        colENdMobile="image-left-end"
        rowStartMobile={2}
      >
        {firstBatch.map(person => (
          <Person key={person.id} {...person} />
        ))}
      </Box>

      <Box
        colStart="column-center-start"
        colEnd="column-center-end"
        colStartMobile="image-right-start"
        colENdMobile="image-right-end"
        rowStartMobile={2}
        css={{
          marginTop: vwpx(115),
          '@media(max-width: 500px)': {
            marginTop: vwpx(-8, 'vh', 100),
          },
        }}
      >
        <Person
          css={{
            '@media(max-width: 500px)': {
              marginBottom: vwpx(20, 'vh', 100),
            },
          }}
          key={topPerson.id}
          {...topPerson}
        />
        <Box
          flex
          flexDirection="column"
          justifyContent="center"
          height={vwpx(357)}
          css={{
            marginBottom: vwpx(50),
            '@media(max-width: 500px)': {
              marinBottom: vwpx(0),
            },
          }}
        >
          <div
            css={{
              width: vwpx(38),
              height: vwpx(3),
              marginBottom: vwpx(30),
              backgroundColor: '#000',
              alignSelf: 'center',
            }}
          />

          <Teaser
            text={
              <Text
                type="teaserText"
                css={{
                  '@media(max-width: 500px)': {
                    fontSize: vwpx(50),
                    lineHeight: vwpx(60),
                    textAlign: 'center',
                    marginRight: vwpx(5, 'vw', 100),
                  },
                }}
              >
                {text}
              </Text>
            }
            link={
              <Text
                type="teaserLink"
                as="button"
                onClick={() => setIndex(index => index + 1)}
                css={{
                  border: 'none',
                  background: 'none',
                  padding: 0,
                  appearance: 'none',
                  '@media(max-width: 500px)': {
                    fontSize: vwpx(46),
                    lineHeight: 1.55,
                    marginTop: vwpx(50),
                  },
                }}
              >
                {refreshEmployeeItemsLabel}
              </Text>
            }
          />
        </Box>
        <Person
          css={{
            '@media(max-width: 500px)': {
              marginTop: vwpx(16, 'vh', 100),
            },
          }}
          key={bottomPerson.id}
          {...bottomPerson}
        />
      </Box>

      {!isMobile && (
        <Box colStart="column-right-start" colEnd="column-right-end">
          {secondBatch.map(person => (
            <Person key={person.id} {...person} />
          ))}
        </Box>
      )}
    </Composition>
  )
}

export default PageEmployeeListingBlock

import React from 'react'
import { useInView } from 'react-intersection-observer'
import { Box, useMediaQuery } from 'atomic-layout'
import ImageFadeIn from 'react-image-fade-in'

import { vwpx } from '@/utils'

const AccentColorAwareRatioImage = ({
  image: {
    fluid: { sizes, src },
  },
  width,
  ratio: [ratioWidth, ratioHeight],
  description,
  colorOnlyOnHover,
  children,
  ...rest
}) => {
  const [ref] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })

  const ratio = ratioHeight / ratioWidth
  const isMobile = useMediaQuery({ maxWidth: '500px' })

  return (
    <Box
      ref={ref}
      css={{
        position: 'relative',
        transition: 'background-color 200ms',
        backgroundColor: colorOnlyOnHover ? 'grey' : 'var(--tertiary)',
        maxWidth: '100%',
        width: `${isMobile ? vwpx(192, 500) : vwpx(300)}`,
        height: `calc(${width} * ${ratio})`,
      }}
      {...rest}
    >
      <div
        css={{
          position: 'absolute',
          inset: 0,
          backgroundColor: 'var(--tertiary)',
          mixBlendMode: 'hard-light',
          zIndex: 1,
          maxWidth: '100%',
          width,
          height: `calc(${width} * ${ratio})`,
        }}
      />
      <ImageFadeIn
        src={src}
        sizes={sizes}
        alt={description}
        opacityTransition={0.3}
        css={{
          display: 'block',
          maxWidth: '100%',
          width,
          height: `calc(${width} * ${ratio})`,
          objectFit: 'cover',
          objectPosition: 'center',
          filter: 'hue-rotate(-3deg) saturate(0.8) brightness(0.9)',
        }}
      />
      {children}
    </Box>
  )
}

export default AccentColorAwareRatioImage
